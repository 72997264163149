<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        {{ m.title }}
      </template>
      <a-form-model v-model="data" :model="model" :errors="errors" :config="{ dense: false, hideDetails: false }" class="mb-3" />
      <a-loader v-if="loading" />
      <v-row v-else>
        <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
          <div v-for="name in el.name.split('+')" :key="name">
            <a-form-model
              :ref="'field_' + name"
              v-model="data"
              :model="getFormModel([name], modelConfig)"
              :errors="errors"
              :config="{ dense: true }"
              @validate="validate($event)"
            />
          </div>
        </v-col>
      </v-row>

      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()" :title="'Применить'" />
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "@/components/mixings";

export default {
  components: {},
  mixins: [getForm, submitForm, removeEl, getAccess, genModel],
  props: {
    value: Boolean,
    id: Number,

    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.accReportAccount,
      api: null,
      loaded: false,
      modelForm: null,
      typeSelected: null,
      data: { type: null },
    };
  },
  computed: {
    modelConfig: {
      get() {
        let model = this.calcModel(this.typeSelected);

        return model;
      },
    },
    model: {
      get() {
        return this.getModelList(this.m, "form", true);
      },
    },
  },
  created() {
    this.loaded = false;
    //  this.updateData(this.id);
    if (this.config?.type) this.data.type = this.config?.type;
    this.loaded = !false;
  },
  watch: {
    "data.type"(v) {
      this.typeSelected = v;
      if (v) this.calcModel(v);
    },
  },
  methods: {
    calcModel(t) {
      //переназначить функцию, потому что немного отличается от того что в библиотеке genModel
      if (!t) return;
      this.typeSelected = null;
      if (this.loaded) this.data = { type: t };

      const config = this.m.config[t];
      let model = JSON.parse(JSON.stringify(config.form));
      const fields = config.fields ? config.fields.split(",") : [];
      let fieldsRO = config.fieldsRO ? config.fieldsRO.split(",") : [];
      if (this.data.status == 2) fieldsRO = fields;
      const fieldsForm = config.fieldsForm ? config.fieldsForm.split(",") : [];
      model = model.filter((el) => {
        return fields.includes(el.name);
      });
      model.forEach((el) => {
        if (fieldsRO.includes(el.name)) {
          el.readonly = true;
        }
      });
      let mForm = [];
      fieldsForm.forEach((field) => {
        let f = field.split("#");
        mForm.push({ name: f[0], size: f[1] || 12 });
      });

      this.modelForm = mForm;
      this.typeSelected = t;
      this.fillFormFromModel(model);
      if (t == this.config?.type) {
        for (const name in this.config) {
          this.$set(this.data, name, this.config[name]);
        }
      }
      return model;
    },
    async post(data, api) {
      this.afterSave(this.data, status);
      let sql;
      sql = `
select res.*,sub1.name as 'sub1name' from (      
SELECT subconto_1,subconto_2, SUM(VALUE) AS 'value',COUNT(1) AS 'rows', SUM(val_in) AS 'val_in', SUM(val_out) AS 'val_out' FROM (
SELECT id, createdon,date_doc,base_name,base_id,credit_subconto_value_1 AS 'subconto_1',
${data?.isSubconto2 ? "credit_subconto_value_2" : "null"} AS 'subconto_2',-value AS 'value', null as val_in,   value as val_out
FROM accounting_record WHERE credit_account LIKE '${this.typeSelected}%'
UNION ALL
SELECT id, createdon,date_doc,base_name,base_id,debit_subconto_value_1 AS 'subconto_1',
${data?.isSubconto2 ? "debit_subconto_value_2" : "null"} AS 'subconto_2',VALUE , value as val_in,  null as val_out
FROM accounting_record WHERE debit_account LIKE '${this.typeSelected}%'
) AS t
GROUP BY subconto_1, subconto_2
${data?.show_zero ? " " : "HAVING SUM(VALUE)<>0"}
) as res
`;
      if (this.typeSelected == "71") sql += ` left join accounting_dir_employees sub1 on sub1.id=res.subconto_1`;
      else if (this.typeSelected == "60") sql += ` left join accounting_dir_vendors sub1 on sub1.id=res.subconto_1`;
      //  this.data.sql = sql;
      this.data.title =
        this.m.typeReport.find((el) => {
          return el.value == this.typeSelected;
        })?.text || "";
      this.$emit("apply", this.data);
    },
    afterSave(data) {
      this.$emit("input");
    },
  },
};
</script>
